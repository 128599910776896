import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Instagram from '../components/Images/Instagram';


const InstagramPage = () => (
  <Layout>
    <SEO title="Instagram" />

    <div className="content page">
      <h1>Instagram</h1>
      <p>
        <a
          href="https://www.instagram.com/lieeparis/" class="button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Follow @lieeparis
        </a>
        Suivez Lié(e) sur Instagram
      </p>
      <i>Derniers posts:</i>
      <Instagram />
    </div>
  </Layout>
);

export default InstagramPage;
