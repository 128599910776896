import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';



const Instagram = () => {
  const results = useStaticQuery(graphql`
  query {
    allInstaNode {
      edges {
        node {
          id
          likes
          comments
          mediaType
          preview
          original
          timestamp
          caption
          localFile {
            childImageSharp {
              fixed(width: 300, height: 300, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          # Only available with the public api scraper
          thumbnails {
            src
            config_width
            config_height
          }
          dimensions {
            height
            width
          }
        }
      }
    }
  }
  `);

  const posts = results.allInstaNode.edges.sort((a, b) => b.node.timestamp - a.node.timestamp);

  return (
    <div className="insta-gallery">
      {posts.map(({ node: post }) => (
        <a
          href={`https://www.instagram.com/p/${post.id}/`}
          className="insta-image"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img fixed={post.localFile.childImageSharp.fixed}/>
        </a>
      ))}
    </div>
  );
};

export default Instagram;